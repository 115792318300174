import { LoggedOutHeader } from 'components/LoggedOutHeader'
import dynamic from 'next/dynamic'
import { PageWrapper } from 'containers/styles'
import { AppWrapper } from 'styles/AppWrapper'
import { MobileMovableWrapper } from 'styles/MobileMovableWrapper'
import { Modals } from 'components/Modals'
import { useRef } from 'react'

const Footer = dynamic(() =>
  import('components/PublicComponents/Footer').then((res) => res.Footer)
) as unknown as React.FC<any>

export function withoutAuth(Component, header = true) {
  return ({ data }) => {
    // Used currently for resume example page as a scroll container
    const appWrapperRef = useRef(null)

    return header ? (
      <MobileMovableWrapper>
        <LoggedOutHeader />
        <Modals />
        <AppWrapper ref={appWrapperRef} scroll loggedOut>
          <PageWrapper noPaddingTop>
            <Component data={data} appWrapperRef={appWrapperRef} />
            <Footer />
          </PageWrapper>
        </AppWrapper>
      </MobileMovableWrapper>
    ) : (
      <Component data={data} />
    )
  }
}
